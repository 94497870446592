import * as React from "react";
import { Link, HeadFC, graphql } from "gatsby";
import Layout from "../components/layouts/Default/Default";
import { CommonHead } from "../components/commons/Head/Head";

const NotFoundPage = () => {
  return (
    <Layout title="Page not found" pageType="SubPage">
      <h1>Page not found</h1>
      <p>
        Sorry 😔, we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead>
    <title>Not found</title>
  </CommonHead>
);
